<template>
  <div class="row bg-white">
    <div class="col-12">
      <!-- <c-card :noHeader="true" class="cardClassDetailForm dashborad">
        <template slot="card-detail">
          <div class="col-2">
            <c-plant 
              class="none-bottom" 
              type="none" 
              name="plantCd" 
              v-model="searchParam.plantCd"
              @datachange="plantChange" />
          </div>
        </template>
      </c-card> -->
      <c-card :noHeader="true" class="cardClassDetailForm dashborad">
        <template slot="card-detail">
          <div class="col-12">
            <apexchart 
              ref="chart" 
              height="450" 
              type="bar"
              :width="chart.chartWidth"
              :options="chart.chartOptions" 
              :series="chart.series"></apexchart>
          </div>
        </template>
      </c-card>
      <c-table
        class="q-mt-sm"
        ref="table"
        title="화학자재(구성성분) 규제법규/허가량"
        tableId="impr01"
        :merge="grid.merge"
        :columns="grid.columns"
        :data="grid.data"
        gridHeight="500px"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name === 'col1' && props.row.col1">
            <q-chip
              v-for="(item, index) in chipDatas(props.row['col1'])"
              :key="index"
              outline square
              color="light-green"
              text-color="white"
              style="margin-bottom:4px !important;">
              {{item}}
            </q-chip>
          </template>
          <template v-if="col.name === 'col3' && props.row.col3">
            <q-chip
              v-for="(item, index) in chipDatas(props.row['col3'])"
              :key="index"
              outline square
              color="light-green"
              text-color="white"
              style="margin-bottom:4px !important;">
              {{item}}
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'week-dashboard',
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      searchParam: {
        plantCd: '',
      },
      chart: {
        chartOptions: {
          title: {
            text: '안전점검 조치부서별 개선/지연'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded',
              dataLabels: {
                position: 'top', // top, center, bottom
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opt) {
              return val + "건";
            },
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            }
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['안전환경팀', '생산팀 A', '생산팀 B', '기술팀 A', '기술팀 B'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          colors: ['#21BA45', '#C10015'],
          fill: {
            opacity: 1,
            colors: ['#21BA45', '#C10015']
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " 건"
              }
            }
          }
        },
        series: [{
          name: '개선 건 수',
          // data: [0, 0, 0, 0, 0]
          data: [213, 190, 203, 187, 126]
        }, {
          name: '지연 건 수',
          // data: [0, 0, 0, 0, 0]
          data: [180, 76, 30, 69, 21]
        }],
        chartWidth: '80%',
      },
      grid: {
        merge: [
          { index: 0, colName: 'mdmChemMaterialId' },
          { index: 1, colName: 'mdmChemMaterialId' },
          { index: 2, colName: 'mdmChemMaterialId' },
        ],
        columns: [
          {
            name: 'materialCd',
            field: 'materialCd',
            label: '자재코드',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재명',
            align: 'left',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '자재별 규제법규',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'col2',
            field: 'col2',
            label: '화학자재',
            sortable: false,
            child: [
              {
                name: 'casNo',
                field: 'casNo',
                label: 'CAS No.',
                align: 'center',
                style: 'width:120px',
                sortable: false,
              },
              {
                name: 'chemNmKr',
                field: 'chemNmKr',
                label: '화학물질명(KOR)',
                align: 'left',
                style: 'width:220px',
                sortable: false,
              },
              {
                name: 'chemNmEn',
                field: 'chemNmEn',
                label: '화학물질명(ENG)',
                align: 'left',
                sortable: false,
              },
              {
                label: '함유량(%) - 대표값',
                align: 'center',
                name: 'limitRepval',
                field: 'limitRepval',
                style: 'width:100px',
                sortable: false,
              },
              {
                name: 'col3',
                field: 'col3',
                label: '물질별 규제법규',
                align: 'left',
                style: 'width:200px',
                sortable: false,
                type: 'custom',
              },
              {
                name: 'col4',
                field: 'col4',
                label: '위험물 허가량',
                align: 'center',
                style: 'width:100px',
                sortable: false,
              },
            ]
          },
        ],
        data: [],
      },
      editable: true,
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    // window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.setChart();
      this.getList();
    },
    setChart() {
      setTimeout(() => {
        this.chart.chartWidth = '100%';
      }, 200);
      this.$refs['chart'].refresh();
    },
    getList() {
      this.grid.data = [
        {
          mdmChemMaterialId: 'MCM0000001', 
          materialName: '암모니아수 25%', materialNameEn: '암모니아수 25%',
          col1: '산업안전보건법,화학물질관리법',
          casNo: '123-91-1', 
          chemNmKr: 'p-다이옥세인', chemNmEn: 'p-Dioxane', 
          limitRepval: '20',
          col3: '산업안전보건법',
          col4: '',
        },
        {
          mdmChemMaterialId: 'MCM0000001', 
          materialName: '암모니아수 25%', materialNameEn: '암모니아수 25%',
          col1: '산업안전보건법,화학물질관리법',
          casNo: '7778-54-3', 
          chemNmKr: '차아염소산 칼슘', chemNmEn: 'Calcium hypochlorite', 
          limitRepval: '30',
          col3: '',
          col4: '',
        },
        {
          mdmChemMaterialId: 'MCM0000001', 
          materialName: '암모니아수 25%', materialNameEn: '암모니아수 25%',
          col1: '산업안전보건법,화학물질관리법',
          casNo: '108-90-7', 
          chemNmKr: '클로로벤젠', chemNmEn: 'Chlorobenzene', 
          limitRepval: '50',
          col3: '화학물질관리법',
          col4: '',
        },
        {
          mdmChemMaterialId: 'MCM0000010', 
          materialName: '시판(450G)', materialNameEn: '시판(450G)',
          col1: '위험물안전관리법',
          casNo: '57-50-1', 
          chemNmKr: 'β-D-Fructofuranosyl-α-D-glucopyranoside', chemNmEn: 'β-D-Fructofuranosyl-α-D-glucopyranoside', 
          limitRepval: '50',
          col3: '위험물안전관리법',
          col4: '100 TON',
        },
        {
          mdmChemMaterialId: 'MCM0000010', 
          materialName: '시판(450G)', materialNameEn: '시판(450G)',
          col1: '위험물안전관리법',
          casNo: '62-53-3', 
          chemNmKr: '아닐린', chemNmEn: 'Aniline', 
          limitRepval: '50',
          col3: '위험물안전관리법',
          col4: '30 TON',
        },
        {
          mdmChemMaterialId: 'MC00000009', 
          materialName: '불용성 (P)', materialNameEn: '불용성 (P)',
          col1: '산업안전보건법',
          casNo: '64-19-7', 
          chemNmKr: '초산', chemNmEn: 'Acetic acid', 
          limitRepval: '40',
          col3: '산업안전보건법',
          col4: '',
        },
        {
          mdmChemMaterialId: 'MC00000009', 
          materialName: '불용성 (P)', materialNameEn: '불용성 (P)',
          col1: '산업안전보건법',
          casNo: '1600-27-7', 
          chemNmKr: '초산수은(II)', chemNmEn: 'Mercuric acetate', 
          limitRepval: '30',
          col3: '산업안전보건법',
          col4: '',
        },
        {
          mdmChemMaterialId: 'MC00000009', 
          materialName: '불용성 (P)', materialNameEn: '불용성 (P)',
          col1: '산업안전보건법',
          casNo: '301-04-2', 
          chemNmKr: '이초산납', chemNmEn: 'Lead acetate', 
          limitRepval: '30',
          col3: '산업안전보건법',
          col4: '',
        },
      ]
    },
    chipDatas(val) {
      return this.$_.split(val, ',');
    },
    plantChange() {
    },
  }
};
</script>